import React from 'react';
import SectionTitle from '../../components/SectionTitle';
import Strings from '../../constants/strings';
import LoadingDialog from '../LoadingDialog';
import { List, ListItem, ListItemText } from '@material-ui/core';
import TextField from '../mui/TextField';
import Toggle from '../mui/Toggle';
// API
import { connect } from 'react-redux';
import * as actions from '../../actions';
import validate from 'validate.js';
import CheckboxOperandValue_PJ from '../CheckboxEnableValue_PJ';
import CheckBoxEnableValueCNAE from '../CheckBoxEnableValueCNAE';
import UserLanguage from '../configurations/UserLanguage.js';
import ButtonSecondary from '../mui/ButtonSecondary';

let language = UserLanguage.getLanguage();

validate.validators.presence.message = " é obrigatório.";

const column2Style = {
    width: 530,
};

const selectedSubItemStyle = {
    width: '370px',
    background: '#dddddd',
    color: '#0069ff'
};

const subItemStyle = {
    width: '370px',
    background: '#ffffff'
};

const cardContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: null
};

const toggleStyle = {
    
};

const columnStyle = {
    width: 385,
};

const textStyle = {
    float: 'right'
};

class BackgroundCheck_PJ extends React.Component {
    constructor(props) {
        super(props);

        const menuItens = [
            { text: Strings.backgroundCheckConfigurations_PJ.MENU_BACKGROUND_CKECK_CONFIG[language]},
            { text: Strings.backgroundCheckConfigurations_PJ.MENU_BASIC_DATA[language] },
            { text: Strings.backgroundCheckConfigurations_PJ.MENU_KNOW_YOUR_CLIENT },            
            { text: Strings.backgroundCheckConfigurations_PJ.MENU_LAWSUITS_PRESENCE[language] },
            { text: Strings.backgroundCheckConfigurations_PJ.QSA[language] },
        ];
        
        this.state = {
            configurations: props.configurations,
            DEFAULT_KEY: 'menuListItem_',
            menuItens: menuItens,
            selected: menuItens[0]
        }
    }

    // -- HANDLERS -- //
    handleScoreToggle = (index, item , event) => {
        const funcName = `handleScoreToggle`;
        const newState = this.state;
        let value = event.target.checked;

        if (newState.text === Strings.backgroundCheckConfigurations_PJ.QSA[language]){
            if(index == 'PROCESS_COURT_TYPES'){
                newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs[index].UseIndividualScore = value;
            }else{
                newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs[index].ScoreIndividual = value;
            }
        }
        else{
            newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs[index][item][0].UseIndividualScore = value;
        }
        
        this.setState({ newState });
        this.props.onSave(newState.configurations);
    }

    handleValueRelatedPeopleMatchChange = (index, event, value, newValue) => {
        const newState = this.state;

        newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs[index[0]][index[1]][index[2]] = newValue || value;

        this.setState({ newState });
        this.props.onSave(newState.configurations);
    }

    handleValueRelatedPeopleMatchMake = (index, event, value, newValue) => {
        const newState = this.state;

        if(index[2] === null) //add item
            newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs[index[0]][index[1]][0].Value.push("");
        else //remove item
            newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs[index[0]][index[1]][0].Value.splice(index[2], 1);

        this.setState({ newState });
        this.props.onSave(newState.configurations);
    }

    handleReprovationPointChange = (event) => {
        const state = this.state;
        state.configurations.BackGroundCheckConfigsPJ.LimitScore = event.target.value;
        this.setState({ state });
        this.props.onSave(state.configurations);
    };

    handleRelatedLimitQtyChange = (event) => {
        const state = this.state;
        state.configurations.BackGroundCheckConfigsPJ.RelatedLimitQty = event.target.value;
        this.setState({ state });
        this.props.onSave(state.configurations);
    };

    handledataRelatedPeopleContentToggle = (index, event) => {
        const newState = this.state;
        newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs[index].Enabled = event.target.checked;
        this.setState({ newState });
        this.props.onSave(newState.configurations);
    }

    handleValueRelatedPeopleContentMatchChange = (index, event, value, newValue) => {
        value = event.target.value;
        newValue = event.target.value
        const funcName = `handleValueRelatedPeopleContentMatchChange`;
        const newState = this.state;
        console.info(funcName, `(before)`, `newState: `, newState, `value: `, value, `index: `, index, `newValue: `, newValue);
        if (index === 'BigBoostConfigsToken') {
            newState.configurations.BigBoostConfigs.Token = newValue || value;
            if (newState.configurations.BigBoostConfigs.Token !== null && newState.configurations.BigBoostConfigs.Token !== "") {
                localStorage.setItem("visitedBGCPage", false);
            }
        } else {
            if (index[1] === "Score" || index[1] === "Value") {
                if(index[0] != "PF_INCOME_ESTIMATE") {
                    newValue = parseInt(newValue);
                    if(isNaN(parseInt(newValue)))
                        newValue = 0;
                }  
            }
            if (index.length === 2) {
                if (index[0] == "PROCESS_COURT_TYPES") {
                    newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs[index[0]].Types[0][index[1]] = value || newValue;
                } else {
                    newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs[index[0]][index[1]] = newValue || value;
                }
            }
            else if (index.length === 3 && index[0] === "KYC_AGE" && (index[1] === "Score" || index[1] === "Value" || index[1] === "Operand")) {
                var datasetConfig = newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs[index[0]][index[1]];
                console.info(funcName, `datasetConfig: `, datasetConfig);

                newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs[index[0]]["Configs"][index[2]][index[1]] = newValue;

            }
            else if (index[0] == "PROCESS_COURT_TYPES") {
                if (index[3] === "Scores")
                    newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs[index[0]][index[1]][0][index[3]][index[2].toUpperCase()] = Number(newValue);
                else if (index[3] === "Value") {
                    newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs[index[0]][index[1]][0][index[3]][index[2]] = value;
                } else if (index[2] === "Party") {
                    newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs[index[0]][index[1]][0][index[2]] = index[3][value];
                }
            }
            else {
                if (index[1] === "Operand") {
                    newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs[index[0]].Configs[index[2]][index[1]] = newValue || value;
                }
                if ((index[1] === "Value") || (index[1] === "Score")) {
                    newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs[index[0]].Configs[index[2]][index[1]] = newValue || value;
                }

            }
        }
        console.info(funcName, `(after)`, `newState: `, newState, `value: `, value, `index: `, index, `newValue: `, newValue);
        this.setState({ newState });
        this.props.onSave(newState.configurations);
    }

    handleValueMatchMake = (index, event, value, newValue) => {
        const newState = this.state;

        if(index[2] === null) { //add item
            newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs[index[0]][index[1]][0].Value.push("");
        } else { //remove item
            newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs[index[0]][index[1]][0].Value.splice(index[2], 1);
        }

        this.setState({ newState });
        this.props.onSave(newState.configurations);
    }

    handleValueRelatedPeopleContentMatchMake = (index, event, value, newValue) => {
        const newState = this.state;

        if(index[2] === null) { //add item
            newState.configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs[index[0]][index[1]].push({
                "Operand": "=",
                "Value": "",
                "Score": 0
            });
        } else { //remove item
            newState.configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs[index[0]][index[1]].splice(index[2], 1);
        }

        this.setState({ newState });
        this.props.onSave(newState.configurations);
    }

    handleToggle = (event, toggled) => {
        const target = event.target;
        const name = target.name;
        const newState = this.state;
        newState.configurations.BackGroundCheckConfigsPJ[name] = toggled;
        
        if (name === "ReturnBoostRawData" && toggled) 
        {
            newState.configurations.BackGroundCheckConfigsPJ["ReturnBoostRawDataIfApproved"] = false;
            newState.configurations.BackGroundCheckConfigsPJ["ReturnBoostRawDataIfNotApproved"] = false;
        }
        
        if (name === "ReturnBoostRawDataIfApproved")
        {
            newState.configurations.BackGroundCheckConfigsPJ["ReturnBoostRawData"] = false;
            newState.configurations.BackGroundCheckConfigsPJ["ReturnBoostRawDataIfNotApproved"] = false;

        }
        if (name === "ReturnBoostRawDataIfNotApproved")
        {
            newState.configurations.BackGroundCheckConfigsPJ["ReturnBoostRawData"] = false;
            newState.configurations.BackGroundCheckConfigsPJ["ReturnBoostRawDataIfApproved"] = false;
        }

        if (validate(newState.configurations, this.constraints) === undefined) {
            this.setState(newState);
            this.props.onSave(newState.configurations);
        }
    };

    handleDontLetSaveGroupWithoutToken = (configurations) =>
    {
        if (this.state.selected.text == "Configurações gerais" && this.state.configurations.BackGroundCheckConfigsPJ)
        {
            if (configurations.BackGroundCheckConfigsPJ.ReturnBoostRawData || configurations.BackGroundCheckConfigsPJ.ReturnBoostRawDataIfApproved || configurations.BackGroundCheckConfigsPJ.ReturnBoostRawDataIfNotApproved)
            {
                if (!configurations.BigBoostConfigs.Token) {
                    localStorage.setItem("visitedBGCPage", true);
                    return false;
                } else {
                    localStorage.setItem("visitedBGCPage", false);
                }
            } else { // in case of all toggles was off
                localStorage.setItem("visitedBGCPage", false);
                return
            }
        }
    }

    changeSelected = (value) => {
        var visitedBGCPage = localStorage.getItem("visitedBGCPage")
        if (visitedBGCPage == "true")
        {
            alert("Só é possivel Retornar dos dados utilizados no Background Check com o Token do BigBoost preenchido.\n\nInsira um Token ou Desabilite as opções de retorno de dados.");
            return;   
        }
        const state = this.state;
        state.selected = value;
        this.setState(state.selected);
    }
    
    toggleLaws = (index, event, value) => {
        const newState = this.state;
        newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs.PROCESS_QTY.Enabled = value;
        newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs.PROCESS_COURT_TYPES_PJ.Enabled = value;
        this.setState({ newState });
        this.props.onSave(newState.configurations);
    }
    addCompanyAgeRule = () => {
        const newState = this.state;
        let last = newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.COMPANY_AGE.Configs.length;
        if(last > 0 && last < 9)
        {
            let config = {
                "Operand" : newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.COMPANY_AGE.Configs[last -1].Operand,
                "Value" : newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.COMPANY_AGE.Configs[last -1].Value,
                "Score" : newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.COMPANY_AGE.Configs[last -1].Score
            }
            newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.COMPANY_AGE.Configs.push(config);
            this.setState({ newState });
            this.props.onSave(newState.configurations);
        }
        
    }
    deleteCompanyAgeRule = () => {
        const newState = this.state;
        let last = newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.COMPANY_AGE.Configs.length;
        if(last > 1)
        {
            let config = newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.COMPANY_AGE.Configs[last - 1];
            newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.COMPANY_AGE.Configs.pop(config);
            this.setState({ newState });
            this.props.onSave(newState.configurations);
        }        
    }

    onGenerate = (event, newValue) => {
        const newState = this.state

        if (newValue.includes(',')) {
            let listWords = newValue.split(',');
            for (let i = 0; i < listWords.length; i++) {
                newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs[event[0]].Types[0][event[1]].push(listWords[i])
            }
        } else {
            newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs[event[0]].Types[0][event[1]].push(newValue)
        }

        this.setState({ newState });
        this.props.onSave(newState.configurations);

    }

    onGenerateRelated = (event, newValue) => {
        const newState = this.state

        if (newValue.includes(',')) {
            let listWords = newValue.split(',');
            for (let i = 0; i < listWords.length; i++) {
                newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs[event[0]].Types[0][event[1]].push(listWords[i])
            }
        } else {
            newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs[event[0]].Types[0][event[1]].push(newValue)
        }

        this.setState({ newState });
        this.props.onSave(newState.configurations);

    }

    handledataToggle = (index, event) => {
        const newState = this.state;
        
        //Para separar os toggles        
        newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs[index].Enabled = event.target.checked;
        this.setState({ newState });
        this.props.onSave(newState.configurations);
    }

    handleValueMatchChange = (index, event, value, newValue) => {
        value = event.target.value;
        newValue = event.target.value
        const funcName = `handleValueMatchChange`;
        const newState = this.state;
        console.info(funcName, `(before)`, `newState: `, newState, `value: `, value, `index: `, index, `newValue: `, newValue);
        if (index === 'BigBoostConfigsToken') {
            newState.configurations.BigBoostConfigs.Token = newValue || value;
            if (newState.configurations.BigBoostConfigs.Token !== null && newState.configurations.BigBoostConfigs.Token !== "")
            {
                localStorage.setItem("visitedBGCPage", false);
            }
        } else {
            if (index[1] === "Score" || index[1] === "Value") {
                newValue = parseInt(newValue);
                if(isNaN(parseInt(newValue)))
                    newValue = 0;
            }
            
            if (index.length === 2) {
                if (index[0] === "PROCESS_COURT_TYPES_PJ"){
                newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs[index[0]].Types[0][index[1]] = value
                } else {
                newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs[index[0]][index[1]] = newValue || value;
                }
            }
            else if (index.length === 3 && index[0] === "KYC_AGE" && (index[1] === "Score" || index[1] === "Value" || index[1] === "Operand")) {
                var datasetConfig = newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs[index[0]][index[1]];
                console.info(funcName, `datasetConfig: `, datasetConfig );

                if (datasetConfig === null || datasetConfig === undefined || typeof datasetConfig !== "object" || !Array.isArray(datasetConfig)) {
                    newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs[index[0]][index[1]] = [];
                }
                newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs[index[0]][index[1]][index[2]] = newValue || value;
            }
            else if (index[0] == "PROCESS_COURT_TYPES_PJ"){
                if (index[3] === "Scores")
                newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs[index[0]][index[1]][0][index[3]][index[2].toUpperCase()] = value;
                else if (index[3] === "Value"){
                newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs[index[0]][index[1]][0][index[3]][index[2]] = newValue;
                } else if (index[2] === "Party"){
                newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs[index[0]][index[1]][0][index[2]] = index[3][newValue];
                }
            }
            else { 
                if (index[1] === "Operand")
                {
                    newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs[index[0]].Configs[index[2]][index[1]] = newValue || value;
                }
                if ((index[1] === "Value") || (index[1] === "Score"))     
                {
                    newState.configurations.BackGroundCheckConfigsPJ.DataSetsConfigs[index[0]].Configs[index[2]][index[1]] = newValue || value;
                }
                
            }
        }
        console.info(funcName, `(after)`, `newState: `, newState, `value: `, value, `index: `, index, `newValue: `, newValue);
        this.setState({ newState });
        this.props.onSave(newState.configurations);
    }


    // -- RENDER -- //

    renderRelatedLimitQty (configurations, errors)
    {
        if (this.state.configurations.BackGroundCheckConfigsPJ.UseRelatedLimit)
        {
            return(
                <div style={{width: '70%', marginLeft: '10px', marginTop: '10px'}}>
                <TextField
                    inline
                    type='number'
                    floatingLabelText={Strings.backgroundCheckConfigurations_PJ.RELATED_LIMIT_QTY[language]}
                    value={configurations.BackGroundCheckConfigsPJ.RelatedLimitQty}
                    name='LimitScore'
                    onChange={this.handleRelatedLimitQtyChange}
                    errorText={errors ? errors.ScoreRules : ''}
                    tooltip={Strings.backgroundCheckConfigurations_PJ.RELATED_LIMIT_QTY_TEXT[language]}
                />
                </div>)
        }
    }

    renderContent(configurations, errors) {
        console.info(`renderContent :: DatasetsConfigs :: `, configurations.BackGroundCheckConfigsPJ.DataSetsConfigs, `DataSetsConfigs.KYC_COURT_ORDERS: `, configurations.BackGroundCheckConfigs.DataSetsConfigs.KYC_COURT_ORDERS.Configs)

        const multiFilter = (obj)=> [
            {
                type: 'select',
                field: 'Operand',
                label: Strings.backgroundCheckConfigurations.OPERAND[language],
                styleWidth: '80px',
                values: ['>=', '=', '<=', '<', '>'],
                default: '>=',
                value: obj.Operand
            },

            {
                type: 'number',
                field: 'Value',
                value: obj.Value,
            },
            {
                type: 'number',
                field: 'Score',
                label: Strings.backgroundCheckConfigurations.SCORE,
                value: obj.Score
            }
        ];

        const nameMatchTextStyle = {
            display: (configurations.BackGroundCheckConfigsPJ.ReturnBoostRawData || 
                configurations.BackGroundCheckConfigsPJ.ReturnBoostRawDataIfApproved ||
                configurations.BackGroundCheckConfigsPJ.ReturnBoostRawDataIfNotApproved ? "block" : "none"),
        };

        switch (this.state.selected.text) {
            case Strings.backgroundCheckConfigurations_PJ.MENU_BACKGROUND_CKECK_CONFIG[language]:
            return (
                <div>
                    {/* <SectionTitle text={Strings.backgroundCheckConfigurations_PJ.BACKGROUND_CHECK[language]} /> */}

                    <p>{Strings.backgroundCheckConfigurations_PJ.BACKGROUND_CHECK_TEXT[language]}</p>

                    <TextField
                        inline
                        type='number'
                        floatingLabelText={Strings.backgroundCheckConfigurations_PJ.REPROVATION_POINT[language]}
                        value={configurations.BackGroundCheckConfigsPJ.LimitScore}
                        name='LimitScore'
                        onChange={this.handleReprovationPointChange}
                        errorText={errors ? errors.ScoreRules : ''}
                        tooltip={Strings.backgroundCheckConfigurations_PJ.REPROVATION_POINT_TEXT[language]}
                    />
                    
                    <div style = {{display: "flex"}}>

                        <div style={{marginRight: '10px', marginTop: '10px'}}>
                            <Toggle
                                tooltip={Strings.backgroundCheckConfigurations_PJ.USE_RELATED_LIMIT_TEXT[language]}
                                style={toggleStyle}
                                label={Strings.backgroundCheckConfigurations_PJ.USE_RELATED_LIMIT[language]}
                                toggled={configurations.BackGroundCheckConfigsPJ.UseRelatedLimit}
                                name='UseRelatedLimit'
                                onToggle={this.handleToggle}
                            />
                        </div>

                        {this.renderRelatedLimitQty(configurations, errors)}

                    </div>
                
                    <TextField
                        tooltip={null}
                        textStyle={{textStyle}}
                        labelStyle={{fontWeight: 300}}
                        type='text'
                        wrapperStyle={nameMatchTextStyle}
                        floatingLabelText={Strings.backgroundCheckConfigurations_PJ.API_TOKEN_BIGBOOST[language] }
                        value={configurations.BigBoostConfigs.Token}
                        name='BigBoostConfigsToken'
                        onChange={this.handleValueMatchChange.bind(null, 'BigBoostConfigsToken')}
                    />
                </div>
            )

            case Strings.backgroundCheckConfigurations_PJ.MENU_BASIC_DATA[language]:
                let basicDataReturnElement = []
                if("STATUS" in configurations.BackGroundCheckConfigsPJ.DataSetsConfigs){
                    basicDataReturnElement.push(
                        <CheckboxOperandValue_PJ
                            handledataToggle={this.handledataToggle}
                            handleValueMatchChange={this.handleValueMatchChange}
                            data={{
                                name:'STATUS',
                                config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations_PJ,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations_PJ.SCORE
                                    }
                                ]
                            }}
                        />
                    )
                }
                if("PJ_ALL_QSA_OBIT" in configurations.BackGroundCheckConfigsPJ.DataSetsConfigs){
                    basicDataReturnElement.push(
                        <CheckboxOperandValue_PJ
                            handledataToggle={this.handledataToggle}
                            handleValueMatchChange={this.handleValueMatchChange}
                            data={{
                                name:'PJ_ALL_QSA_OBIT',
                                config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations_PJ,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations_PJ.SCORE
                                    }
                                ]
                            }}
                        />
                    )
                }

                if("SIMPLES" in configurations.BackGroundCheckConfigsPJ.DataSetsConfigs){
                    basicDataReturnElement.push(
                        <CheckboxOperandValue_PJ
                            handledataToggle={this.handledataToggle}
                            handleValueMatchChange={this.handleValueMatchChange}
                            data={{
                                name:'SIMPLES',
                                config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations_PJ,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations_PJ.SCORE
                                    }
                                ]
                            }}
                        />
                    )
                }

                if("CNAE" in configurations.BackGroundCheckConfigsPJ.DataSetsConfigs){
                    basicDataReturnElement.push(
                        <CheckBoxEnableValueCNAE
                            handledataToggle={this.handledataToggle}
                            handleValueMatchChange={this.handleValueMatchChange}
                            data={configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.CNAE}
                            dataset={{
                                name:'CNAE',
                                config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations_PJ,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations_PJ.SCORE
                                    }
                                ]
                            }}                  
                        />
                    )
                }

                if("COMPANY_AGE" in configurations.BackGroundCheckConfigsPJ.DataSetsConfigs){
                    configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.COMPANY_AGE.Configs.map((obj, index) => {
                        console.info(`configurations :: KYC_DATA :: obj: `, obj);
                        basicDataReturnElement.push(
                            <CheckboxOperandValue_PJ
                                key={"father_COMPANY_AGE_" + index}
                                handledataToggle={this.handledataToggle}
                                handleValueMatchChange={this.handleValueMatchChange}
                                addButton={true}
                                data={{
                                    name: 'COMPANY_AGE',
                                    index: index,
                                    config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs,
                                    stringBase: Strings.backgroundCheckConfigurations_PJ,
                                    fields: multiFilter(obj),
                                    displayToggle: index === 0 ? true: false
                                }}
                            />
                        )
                    })
                    if(configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.COMPANY_AGE.Enabled){
                        basicDataReturnElement.push(<ButtonSecondary 
                            label="Adicionar" 
                            style={{left: "-5%", textTransform: "none" }}
                            onClick={this.addCompanyAgeRule}
                        />,
                        <ButtonSecondary 
                            label="Excluir" 
                            style={{left: "-4%", textTransform: "none" }}
                            onClick={this.deleteCompanyAgeRule}
                        />)
                    }                    
                }

                if("IP_LOCATION_PJ" in configurations.BackGroundCheckConfigsPJ.DataSetsConfigs){
                    basicDataReturnElement.push(
                        <CheckboxOperandValue_PJ
                            handledataToggle={this.handledataToggle}
                            handleValueMatchChange={this.handleValueMatchChange}
                            data={{
                                name:'IP_LOCATION_PJ',
                                config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations_PJ,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations_PJ.SCORE
                                    }
                                ]
                            }}
                        />
                    )
                }

                return (
                    <div>                    
                        {basicDataReturnElement}
                    </div>
                );

            case Strings.backgroundCheckConfigurations_PJ.MENU_KNOW_YOUR_CLIENT:
                let KYCPJReturnElem = []
                if("KYC_CEIS" in configurations.BackGroundCheckConfigsPJ.DataSetsConfigs){
                    KYCPJReturnElem.push(
                        <CheckboxOperandValue_PJ
                            handledataToggle={this.handledataToggle}
                            handleValueMatchChange={this.handleValueMatchChange}
                            data={{
                                name:'KYC_CEIS',
                                config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations_PJ,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations_PJ.SCORE
                                    }
                                ]
                            }}
                        />
                    )
                }
                if("KYC_CEIS_INACTIVE" in configurations.BackGroundCheckConfigsPJ.DataSetsConfigs){
                    KYCPJReturnElem.push(
                        <CheckboxOperandValue_PJ
                            handledataToggle={this.handledataToggle}
                            handleValueMatchChange={this.handleValueMatchChange}
                            data={{
                                name:'KYC_CEIS_INACTIVE',
                                config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations_PJ,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations_PJ.SCORE
                                    }
                                ]
                            }}
                        />
                    )
                }
                if("KYC_PJ_MONEY_LAUDERING" in configurations.BackGroundCheckConfigsPJ.DataSetsConfigs){
                    configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.KYC_PJ_MONEY_LAUDERING.Configs.map((obj, index) => {
                        console.info(`configurations :: KYC_DATA :: obj: `, obj);
                        KYCPJReturnElem.push(
                            <CheckboxOperandValue_PJ
                                key={"father_KYC_PJ_MONEY_LAUDERING_" + index}
                                handledataToggle={this.handledataToggle}
                                handleValueMatchChange={this.handleValueMatchChange}
                                data={{
                                    name: 'KYC_PJ_MONEY_LAUDERING',
                                    index: index,
                                    config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs,
                                    stringBase: Strings.backgroundCheckConfigurations_PJ,
                                    fields: multiFilter(obj),
                                    displayToggle: index === 0 ? true: false
                                }}
                            />
                        )
                    })
                }
                if("KYC_PJ_SLAVERY" in configurations.BackGroundCheckConfigsPJ.DataSetsConfigs){
                    configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.KYC_PJ_SLAVERY.Configs.map((obj, index) => {
                        console.info(`configurations :: KYC_DATA :: obj: `, obj);
                        KYCPJReturnElem.push(
                            <CheckboxOperandValue_PJ
                                key={"father_KYC_PJ_SLAVERY_" + index}
                                handledataToggle={this.handledataToggle}
                                handleValueMatchChange={this.handleValueMatchChange}
                                data={{
                                    name: 'KYC_PJ_SLAVERY',
                                    index: index,
                                    config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs,
                                    stringBase: Strings.backgroundCheckConfigurations_PJ,
                                    fields: multiFilter(obj),
                                    displayToggle: index === 0 ? true: false
                                }}
                            />
                        )
                    })
                }
                if("KYC_CEPIM" in configurations.BackGroundCheckConfigsPJ.DataSetsConfigs){
                    KYCPJReturnElem.push(
                        <CheckboxOperandValue_PJ
                            handledataToggle={this.handledataToggle}
                            handleValueMatchChange={this.handleValueMatchChange}
                            data={{
                                name:'KYC_CEPIM',
                                config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations_PJ,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations_PJ.SCORE
                                    }
                                ]
                            }}
                        />
                    )
                }
                if("KYC_CEPIM_INACTIVE" in configurations.BackGroundCheckConfigsPJ.DataSetsConfigs){
                    KYCPJReturnElem.push(
                        <CheckboxOperandValue_PJ
                            handledataToggle={this.handledataToggle}
                            handleValueMatchChange={this.handleValueMatchChange}
                            data={{
                                name:'KYC_CEPIM_INACTIVE',
                                config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations_PJ,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations_PJ.SCORE
                                    }
                                ]
                            }}
                        />
                    )
                }
                return (
                    <div>
                        {KYCPJReturnElem}
                    </div>
                );
                    
            case Strings.backgroundCheckConfigurations_PJ.MENU_LAWSUITS_PRESENCE[language]:
                return(                
                <div>

                { (() => {const dataName = 'PROCESS_COURT_TYPES_PJ';
                        const checkBox = (
                            
                        <CheckboxOperandValue_PJ
                            handledataToggle={this.handledataToggle}
                            handleValueMatchChange={this.handleValueMatchChange}
                            handleValueMatchMake={this.handleValueMatchMake}
                            handleScoreToggle={this.handleScoreToggle}
                            onGenerate={this.onGenerate}
                            data={{
                                name : dataName,
                                config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations_PJ,
                                word : "",                                
                                fields: [
                                    {                                    
                                        type: 'scrollbox',
                                        mainField : 'Types',
                                        field : 'Words'
                                    },                                                                                                                           
                                    {
                                        type: 'toggle',
                                        field: 'Types'
                                    },
                                    {
                                        type: 'total_number',
                                        mainField : 'Types',
                                        field: 'TotalScore',
                                        label: Strings.backgroundCheckConfigurations_PJ.TOTALSCORE[language]
                                    },  
                                    {
                                        type: 'selectParty',
                                        mainField : 'Types',
                                        field : 'Party',
                                        values : [ "DEFESA" , "AUTOR", "NEUTRO"],
                                        translate: {
                                            "DEFESA" : "DEFENDANT",
                                            "AUTOR" : "AUTHOR",
                                            "NEUTRO" : "NEUTRAL"
                                        },
                                        translateVal : {
                                            "DEFENDANT" : "DEFESA",
                                            "AUTHOR" : "AUTOR",
                                            "NEUTRAL" : "NEUTRO"
                                        }
                                    },                                                                        
                                ],
                                fieldsArray: {
                                    name: 'Types',
                                    fields: [  
                                        {
                                            type : 'number',
                                            basictype : 'dict',
                                            field: 'Scores',
                                            values : [ "Palavras" , "Posição" , "Tipo"],
                                            translate : {
                                                "Palavras" : "Words",
                                                "Posição" : "Party",
                                                "Tipo" : "Type"
                                            }
                                        },                                      
                                        {
                                            type: 'select',
                                            basictype : 'list',
                                            field: 'Value',
                                            default: '',
                                            values: [ 'CRIMINAL', 'CIVEL', 'COMERCIAL', 'AMBIENTAL', 'DIGITAL', 'PENAL', 'CONSUMIDOR', 'CONTRATUAL', 'TRABALHISTA', 'TRIBUTARIA', 'INQUERITO POLICIAL', 'ESPECIAL CIVEL', 'FAZENDA']
                                        },                                                                                
                                    ]
                                },
                            
                            
                        }}
                    />);
                    
                    if (configurations.BackGroundCheckConfigsPJ.DataSetsConfigs[dataName])
                        return checkBox;
                    
                })()
            }
                    </div>
                    
                )

            
            case Strings.backgroundCheckConfigurations_PJ.QSA[language]:
                /*const dataSetPersonalConfig = {
                        MENU_DEBIT_PRESENCE: {
                            Enabled: configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs.PF_COLLECTIONS_QTY.Enabled
                            || configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs.PF_COLLECTIONS_ORIGINS.Enabled
                            },
                        MENU_LAWSUITS_PRESENCE: {
                            Enabled: configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs.PROCESS_QTY.Enabled
                            || configurations.BackGroundCheckConfigs.DataSetsConfigs.RELATED_PEOPLE.RelatedDataSetsConfigs.PROCESS_COURT_TYPES.Enabled
                            },
                        };*/
            return (
                <div>
                    {/* <SectionTitle text={Strings.backgroundCheckConfigurations_PJ.QSA[language]} /> */}


                    { (() => {const dataName = 'QSA';
                        const checkBox = (
                            
                        <CheckboxOperandValue_PJ
                            handledataToggle={this.handledataToggle}
                            handleValueMatchChange={this.handleValueMatchChange}
                            handleValueMatchMake={this.handleValueMatchMake}
                            handleScoreToggle={this.handleScoreToggle}
                            onGenerate={this.onGenerate}
                            data={{
                                name : dataName,
                                config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations_PJ,
                                word : "",
                                fieldItemArray: {
                                    name: "Relationships",
                                    fields: [
                                        {
                                            type: 'select',
                                            values: [ "QSA", "Ownership"],
                                            default: 'QSA'
                                        }
                                    ]
                                },
                                fields: [
                                                                                                                                                              
                                    {
                                        type: 'toggle',
                                        field: 'Types'
                                    },
                                    {
                                        type: 'total_number',
                                        mainField : 'Types',
                                        field: 'TotalScore',
                                        label: Strings.backgroundCheckConfigurations_PJ.TOTALSCORE[language]
                                    },  
                                                                                                            
                                ]                           
                        }}
                    />);
                    if (configurations.BackGroundCheckConfigsPJ.DataSetsConfigs[dataName])
                        {
                            return checkBox;
                        }
                })()
                }


                    <div>
                        <CheckboxOperandValue_PJ
                            handledataToggle={this.handledataRelatedPeopleContentToggle}
                            handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                            data={{
                                name:'KYC_PEP',
                                config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations.SCORE
                                    }
                                ]
                            }}
                        />

                        <CheckboxOperandValue_PJ
                            handledataToggle={this.handledataRelatedPeopleContentToggle}
                            handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                            data={{
                                name:'KYC_PAST_PEP',
                                config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations_PJ,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations_PJ.SCORE
                                    }
                                ]
                            }}
                        />

                        <CheckboxOperandValue_PJ
                            handledataToggle={this.handledataRelatedPeopleContentToggle}
                            handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                            data={{
                                name:'KYC_CNEP_PF',
                                config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations_PJ,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations_PJ.SCORE
                                    }
                                ]
                            }}
                        />

                        <CheckboxOperandValue_PJ
                            handledataToggle={this.handledataRelatedPeopleContentToggle}
                            handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                            data={{
                                name:'KYC_CNEP_PF_INACTIVE',
                                config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations_PJ,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations_PJ.SCORE
                                    }
                                ]
                            }}
                        />

                        {
                            configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs.KYC_AGE.Configs.map((obj, index) => {
                                console.info(`configurations :: KYC_AGE :: obj: `, obj);
                                console.info(`DataSetConfigs: `, configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs);
                                return (
                                    <CheckboxOperandValue_PJ
                                        key={"father_KYC_AGE_" + index}
                                        handledataToggle={this.handledataRelatedPeopleContentToggle}
                                        handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                                        data={{
                                            name: 'KYC_AGE',
                                            index: index,
                                            config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs,
                                            stringBase: Strings.backgroundCheckConfigurations_PJ,
                                            fields: multiFilter(obj),
                                            displayToggle: index === 0 ? true: false
                                        }}
                                    />
                                )
                            })
                        }

                        <CheckboxOperandValue_PJ
                            handledataToggle={this.handledataRelatedPeopleContentToggle}
                            handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                            data={{
                                name:'KYC_CPF_STATUS',
                                config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations_PJ,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations_PJ.SCORE
                                    }
                                ]
                            }}
                        />

                        {
                            configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs.KYC_PF_SLAVERY.Configs.map((obj, index) => {
                                console.info(`configurations :: KYC_PF_SLAVERY :: obj: `, obj);
                                console.info(`DataSetConfigs: `, configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs);
                                return (
                                    <CheckboxOperandValue_PJ
                                        key={"father_KYC_PF_SLAVERY_" + index}
                                        handledataToggle={this.handledataRelatedPeopleContentToggle}
                                        handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                                        data={{
                                            name: 'KYC_PF_SLAVERY',
                                            index: index,
                                            config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs,
                                            stringBase: Strings.backgroundCheckConfigurations_PJ,
                                            fields: multiFilter(obj),
                                            displayToggle: index === 0 ? true: false
                                        }}
                                    />
                                )
                            })
                        }

                        <CheckboxOperandValue_PJ
                            handledataToggle={this.handledataRelatedPeopleContentToggle}
                            handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                            data={{
                                name:'KYC_OBIT_STATUS',
                                config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations_PJ,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations_PJ.SCORE
                                    }
                                ]
                            }}
                        />  

                        {
                            configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs.KYC_PF_MONEY_LAUNDERING.Configs.map((obj, index) => {
                                console.info(`configurations :: KYC_PF_MONEY_LAUNDERING :: obj: `, obj);
                                console.info(`DataSetConfigs: `, configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs);
                                return (
                                    <CheckboxOperandValue_PJ
                                        key={"father_KYC_PF_MONEY_LAUNDERING_" + index}
                                        handledataToggle={this.handledataRelatedPeopleContentToggle}
                                        handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                                        data={{
                                            name: 'KYC_PF_MONEY_LAUNDERING',
                                            index: index,
                                            config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs,
                                            stringBase: Strings.backgroundCheckConfigurations_PJ,
                                            fields: multiFilter(obj),
                                            displayToggle: index === 0 ? true: false
                                        }}
                                    />
                                )
                            })
                        }

                        <CheckboxOperandValue_PJ
                            handledataToggle={this.handledataRelatedPeopleContentToggle}
                            handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                            data={{
                                name:'PF_COLLECTIONS_QTY',
                                config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations_PJ,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations_PJ.SCORE
                                    }
                                ]
                            }}
                        />

                        <CheckboxOperandValue_PJ
                            handledataToggle={this.handledataRelatedPeopleContentToggle}
                            handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                            data={{
                                name:'PF_COLLECTIONS_ORIGINS',
                                config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations_PJ,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations_PJ.SCORE
                                    }
                                ]
                            }}
                        />

                        <CheckboxOperandValue_PJ
                            handledataToggle={this.handledataRelatedPeopleContentToggle}
                            handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                            data={{
                                name:'CLASS_ORG_NAME',
                                config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations_PJ,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations_PJ.SCORE
                                    }
                                ]
                            }}
                        />

                        <CheckboxOperandValue_PJ
                            handledataToggle={this.handledataRelatedPeopleContentToggle}
                            handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                            data={{
                                name:'CLASS_ORG_STATUS',
                                config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations_PJ,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations_PJ.SCORE
                                    }
                                ]
                            }}
                        />

                        <CheckboxOperandValue_PJ
                            handledataToggle={this.handledataRelatedPeopleContentToggle}
                            handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                            data={{
                                name:'PF_INCOME_ESTIMATE',
                                config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations_PJ,
                                fields: [
                                    {
                                        type: 'select',
                                        field: 'Operand',
                                        styleWidth: '80px',
                                        values: ['>', '=', '<'],
                                        default: '>'
                                    },
                                    {
                                        type: 'select',
                                        field: 'Value',
                                        values: ['0 A 1 SM', '1 A 2 SM', '2 A 3 SM', '3 A 5 SM', '5 A 7 SM', '7 A 10 SM', '10 A 15 SM', '15 A 20 SM', 'ACIMA DE 20 SM'],
                                        default: '0 A 1 SM' 
                                    },
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations.SCORE
                                    }
                                ]
                            }}
                        />

                        <CheckboxOperandValue_PJ
                            handledataToggle={this.handledataRelatedPeopleContentToggle}
                            handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                            data={{
                                name:'PUB_EMP_TYPE',
                                config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations_PJ,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations_PJ.SCORE
                                    }
                                ]
                            }}
                        />

                        <CheckboxOperandValue_PJ
                            handledataToggle={this.handledataRelatedPeopleContentToggle}
                            handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                            data={{
                                name:'PUB_EMP_STATUS',
                                config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations_PJ,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations_PJ.SCORE
                                    }
                                ]
                            }}
                        />

                        {/* <CheckboxOperandValue_PJ
                            handledataToggle={this.handledataRelatedPeopleContentToggle}
                            handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                            data={{
                                name:'CELEBRITY_LEVEL',
                                config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations_PJ,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations_PJ.SCORE
                                    }
                                ]
                            }}
                        /> */}

                        {
                            configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs.KYC_CEAF.Configs.map((obj, index) => {
                                console.info(`configurations :: KYC_CEAF :: obj: `, obj);
                                console.info(`DataSetConfigs: `, configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs);
                                return (
                                    <CheckboxOperandValue_PJ
                                        key={"KYC_CEAF_" + index}
                                        handledataToggle={this.handledataRelatedPeopleContentToggle}
                                        handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                                        data={{
                                            name: 'KYC_CEAF',
                                            index: index,
                                            config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs,
                                            stringBase: Strings.backgroundCheckConfigurations_PJ,
                                            fields: multiFilter(obj),
                                            displayToggle: index === 0 ? true: false
                                        }}
                                    />
                                )
                            })
                        }

                        {
                            configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs.KYC_TERRORIST.Configs.map((obj, index) => {
                                console.info(`configurations :: KYC_TERRORIST :: obj: `, obj);
                                console.info(`DataSetConfigs: `, configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs);
                                return (
                                    <CheckboxOperandValue_PJ
                                        key={"father_KYC_TERRORIST_" + index}
                                        handledataToggle={this.handledataRelatedPeopleContentToggle}
                                        handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                                        data={{
                                            name: 'KYC_TERRORIST',
                                            index: index,
                                            config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs,
                                            stringBase: Strings.backgroundCheckConfigurations_PJ,
                                            fields: multiFilter(obj),
                                            displayToggle: index === 0 ? true: false
                                        }}
                                    />
                                )
                            })
                        }


                        <CheckboxOperandValue_PJ
                            handledataToggle={this.handledataRelatedPeopleContentToggle}
                            handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                            data={{
                                name:'SOCIAL_ASSISTANCE',
                                config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations_PJ,
                                fields: [
                                    {
                                        type: 'number',
                                        field: 'Score',
                                        label: Strings.backgroundCheckConfigurations_PJ.SCORE
                                    }
                                ]
                            }}
                        />

                    { (() => {const dataName = 'PROCESS_COURT_TYPES';
                        const checkBox = (
                        <CheckboxOperandValue_PJ
                            handledataToggle={this.handledataRelatedPeopleContentToggle}
                            handleValueMatchChange={this.handleValueRelatedPeopleContentMatchChange}
                            handleValueMatchMake={this.handleValueRelatedPeopleMatchMake}
                            handleScoreToggle={this.handleScoreToggle}
                            onGenerate={this.onGenerateRelated}
                            data={{
                                name : dataName,
                                config: configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs,
                                stringBase: Strings.backgroundCheckConfigurations_PJ,
                                word : "",                                
                                fields: [
                                    {                                    
                                        type: 'scrollbox',
                                        mainField : 'Types',
                                        field : 'Words'
                                    },                                                                                                                           
                                    {
                                        type: 'toggle',
                                        field: 'Types'
                                    },
                                    {
                                        type: 'total_number',
                                        mainField : 'Types',
                                        field: 'TotalScore',
                                        label: Strings.backgroundCheckConfigurations_PJ.TOTALSCORE[language]
                                    },  
                                    {
                                        type: 'selectParty',
                                        mainField : 'Types',
                                        field : 'Party',
                                        values : [ "DEFESA" , "AUTOR", "NEUTRO"],
                                        translate: {
                                            "DEFESA" : "DEFENDANT",
                                            "AUTOR" : "AUTHOR",
                                            "NEUTRO" : "NEUTRAL"
                                        },
                                        translateVal : {
                                            "DEFENDANT" : "DEFESA",
                                            "AUTHOR" : "AUTOR",
                                            "NEUTRAL" : "NEUTRO"
                                        }
                                    },                                                                        
                                ],
                                fieldsArray: {
                                    name: 'Types',
                                    fields: [  
                                        {
                                            type : 'number',
                                            basictype : 'dict',
                                            field: 'Scores',
                                            values : [ "Palavras" , "Posição" , "Tipo"],
                                            translate : {
                                                "Palavras" : "Words",
                                                "Posição" : "Party",
                                                "Tipo" : "Type"
                                            }
                                        },                                      
                                        {
                                            type: 'select',
                                            basictype : 'list',
                                            field: 'Value',
                                            default: '',
                                            values: [ 'CRIMINAL', 'CIVEL', 'COMERCIAL', 'AMBIENTAL', 'DIGITAL', 'PENAL', 'CONSUMIDOR', 'CONTRATUAL', 'TRABALHISTA', 'TRIBUTARIA', 'INQUERITO POLICIAL', 'ESPECIAL CIVEL', 'FAZENDA']
                                        },                                                                                
                                    ]
                                },
                            }}
                            />);
                            
                            if (configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs[dataName])
                            {
                                return checkBox;
                            }
                        })()
                        }
                        </div>
                        </div>
                    )
            default:
                return null;
        }
    }
 
    render() {
        const configurations = this.state.configurations;

        if(this.props.refConfigurations){
            const backGroundCheckConfigsPJ_default = this.props.refConfigurations.BackGroundCheckConfigsPJ;        
            configurations.BackGroundCheckConfigsPJ = configurations.BackGroundCheckConfigsPJ || {};
            configurations.BackGroundCheckConfigsPJ = { ...backGroundCheckConfigsPJ_default, ...configurations.BackGroundCheckConfigsPJ };
            configurations.BackGroundCheckConfigsPJ.DataSetsConfigs = { 
                ...backGroundCheckConfigsPJ_default.DataSetsConfigs, 
                ...configurations.BackGroundCheckConfigsPJ.DataSetsConfigs 
            };
            configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA = {
                ...backGroundCheckConfigsPJ_default.DataSetsConfigs.QSA,
                ...configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA
    
            };
            configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs = {
                ...backGroundCheckConfigsPJ_default.DataSetsConfigs.QSA.RelatedDataSetsConfigs,
                ...configurations.BackGroundCheckConfigsPJ.DataSetsConfigs.QSA.RelatedDataSetsConfigs
            };
        }

        const errors = validate(this.state.configurations, this.constraints, this.options);

        return (
            <div style={cardContainerStyle}  class="bgc-wrapper">
                <div key='left-column' style={columnStyle} class="bgc-top-content">
                    <LoadingDialog open={this.state.loading} />

                    <div style={{ display: 'flex', width: '100%', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'row' }}>

                        {this.state.menuItens.map((item, index) => {
                            return (
                                <List component="nav" style={{padding: 0}}>
                                    <ListItem selected={true}
                                        key={this.state.DEFAULT_KEY + index} 
                                        onClick={this.changeSelected.bind(null, item)} 
                                        style={Object.assign({}, {cursor: "pointer"}, this.state.selected.text === this.state.menuItens[index].text ? selectedSubItemStyle : subItemStyle)}
                                    >
                                        <ListItemText id="ListItemText" primary={item.text} />
                                    </ListItem>
                                </List>
                            )
                        })}
                    </div>
                </div>

                <div key='right-column' style={column2Style} class="bgc-bottom-content">
                    <LoadingDialog open={this.state.loading} />

                    {this.renderContent(configurations, errors)}
                    {this.handleDontLetSaveGroupWithoutToken(configurations)}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        },

        onSelectGroup: (configurations) => {
            dispatch(actions.selectConfigurations(configurations));
        }
    }
};

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
        selectedDomain: state ? state.selectedDomain : null,
        refConfigurations: state ? state.refConfigurations : {}
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BackgroundCheck_PJ);